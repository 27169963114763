import styles from "./RelatedSolutionsList.module.scss";
import { cn } from "@/lib/utils";
import { Link } from "@/components/Link";

function RelatedSolutionsList({ data = [], className, ...props }) {
  if (data.length === 0) return null;

  return (
    <ul className={cn(styles["root"], { [className]: className })} {...props}>
      {data.map((solution, index) => (
        <li key={index}>
          <Link scroll={false} href={solution.uri} animateIcon>
            {solution.title || solution.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export { RelatedSolutionsList };
