import { DownloadTabs } from "@/blocks/global/DownloadTabs";
import { RelatedSolutionsList } from "@/blocks/global/RelatedSolutionsList";
import { Button } from "@/components/Button";
import { WYSIWYG } from "@/components/WYSIWYG";
import fourOhFourTemplateQuery from "@/graphql/templates/four-oh-four.graphql";
import AppLayout from "@/layouts/AppLayout";
import graphqlClient from "@/lib/graphql-client";
import LayoutService from "@/services/LayoutService";
import ThemeService from "@/services/ThemeService";

const Custom404 = ({ theme, layout, data }) => {
  const page = data.pageBy;
  const acf = page.fourOhFourPageAcf;

  const solutions = layout.mainMenu.nodes.find(
    (node) => !node.parentId && node.childItems.nodes.length,
  );

  return (
    <AppLayout seo={page.seo} theme={theme} layout={layout}>
      <section className="container py-6 sm:py-10 md:py-14 max-w-3xl ml-0">
        <h2 className="heading heading--3xl sm:heading--4xl mb-4">
          {acf.introTitle}
        </h2>

        <WYSIWYG className="mb-6">{acf.introText}</WYSIWYG>

        <div className="-mx-3 -my-1.5">
          <Button
            href={acf.primaryButton.url}
            target={acf.primaryButton.target}
            className="mx-3 my-1.5"
          >
            {acf.primaryButton.title}
          </Button>
          <Button
            className="mx-3 my-1.5"
            outline
            href={acf.secondaryButton.url}
            target={acf.secondaryButton.target}
          >
            {acf.secondaryButton.title}
          </Button>
        </div>
      </section>

      <div className="container py-12 text-white bg-gradient-primary grid lg:grid-cols-8 gap-8">
        <div className="col-span-3 pb-12 lg:pb-0 lg:border-r">
          <h2 className="heading heading--2xl mb-4 md:mb-10">
            {acf.ourSolutionsText}
          </h2>

          <RelatedSolutionsList data={solutions.childItems.nodes} />
        </div>

        <div className="col-span-5 pt-12 lg:pt-0 border-t lg:border-none">
          <DownloadTabs theme={theme} />
        </div>
      </div>
    </AppLayout>
  );
};

export async function getStaticProps() {
  const theme = await ThemeService().settings();
  const layout = await LayoutService();

  const pageId = "cG9zdDoxNDQw";
  const response = await graphqlClient(fourOhFourTemplateQuery, { id: pageId });
  const data = response.data;

  return { props: { theme, layout, data } };
}

export default Custom404;
