import { cn } from "@/lib/utils";

function Arrow({ children, className, ...props }) {
  return (
    <button
      type="button"
      className={cn("disabled:opacity-50", { [className]: className })}
      {...props}
    >
      {children}
    </button>
  );
}

export { Arrow };
