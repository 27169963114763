import { TabContext, TabProvider } from "./TabContext";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { cn } from "@/lib/utils";
import { useContext, useEffect, useRef } from "react";
import { Arrow } from "@/components/Arrow";

function TabRoot({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

function Tabs({ className, children }) {
  return (
    <TabProvider>
      <div className={cn({ [className]: className })}>{children}</div>
    </TabProvider>
  );
}

function List({ className, children }) {
  const { active, setActive, previousActive, setShowArrows, setTabAmount } =
    useContext(TabContext);
  const ref = useRef(null);

  useEffect(() => setTabAmount(children.length));

  useEffect(() => {
    function toggleArrows() {
      const { current } = ref;
      if (current) setShowArrows(current.scrollWidth > current.clientWidth);
    }

    toggleArrows();

    window.addEventListener("resize", toggleArrows);
    return () => window.removeEventListener("resize", toggleArrows);
  }, [ref, setShowArrows]);

  useEffect(() => {
    function scrollTabs() {
      const activeTabEl = ref.current.querySelector('[aria-selected="true"]');
      if (!previousActive) return;
      ref.current.scrollTo({
        left: activeTabEl.offsetLeft,
        behavior: "smooth",
      });
    }

    scrollTabs();

    window.addEventListener("resize", scrollTabs);
    return () => window.removeEventListener("resize", scrollTabs);
  }, [active, previousActive]);

  return (
    <ul
      role="tablist"
      ref={ref}
      className={cn(
        "flex hide-scrollbar overflow-scroll items-center relative",
        { [className]: className },
      )}
    >
      {children.map((child, index) => {
        return (
          <li
            key={index}
            role="presentation"
            className="whitespace-nowrap first:ml-0 ml-3"
          >
            <button
              role="tab"
              aria-selected={index === active}
              tabIndex={index === active ? "0" : "-1"}
              type="button"
              onClick={() => setActive(index)}
            >
              {child}
            </button>
          </li>
        );
      })}
    </ul>
  );
}

function Separator({ className }) {
  return <span className={cn("block", { [className]: className })} />;
}

function Panels({ className, children, ...props }) {
  const { active, previousActive } = useContext(TabContext);
  const ref = useRef(null);

  useEffect(() => {
    function scrollPanels() {
      const activePanelEl = ref.current.querySelector('[aria-hidden="false"]');
      if (!previousActive) return;
      ref.current.scrollTo({
        left: activePanelEl.offsetLeft,
        behavior: "smooth",
      });
    }

    scrollPanels();

    window.addEventListener("resize", scrollPanels);
    return () => window.removeEventListener("resize", scrollPanels);
  }, [previousActive]);

  return (
    <div
      ref={ref}
      className={cn("flex hide-scrollbar overflow-hidden relative", {
        [className]: className,
      })}
      {...props}
    >
      {children.map((child, index) => (
        <div
          key={index}
          aria-hidden={index !== active}
          role="tabpanel"
          tabIndex="0"
          className={cn("w-full shrink-0", { hidden: index !== active })}
        >
          {child}
        </div>
      ))}
    </div>
  );
}

function Panel({ children }) {
  return <>{children}</>;
}

function Arrows({ className }) {
  const { tabAmount, active, setActive, showArrows } = useContext(TabContext);
  if (!showArrows) return null;

  return (
    <div
      className={cn("flex space-x-4 justify-end", { [className]: className })}
    >
      <Arrow
        onClick={() => setActive((value) => value - 1)}
        disabled={active === 0}
      >
        <ArrowLeftIcon className="w-5 h-5" />
      </Arrow>

      <Arrow
        onClick={() => setActive((value) => value + 1)}
        disabled={active === tabAmount - 1}
      >
        <ArrowRightIcon className="w-5 h-5" />
      </Arrow>
    </div>
  );
}

export let Tab = Object.assign(TabRoot, {
  Group: Tabs,
  List,
  Separator,
  Panels,
  Panel,
  Arrows,
});
