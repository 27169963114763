import styles from "./DownloadTabs.module.scss";
import { cn } from "@/lib/utils";
import { Link } from "@/components/Link";
import { Tab } from "@/components/Tab";

function DownloadTabs({ theme, className, ...props }) {
  if (theme.allDownloads.length === 0) return null;
  const downloads = theme.allDownloads;

  return (
    <div className={cn(styles["root"], { [className]: className })} {...props}>
      <h2 className={cn("heading heading--2xl")}>{theme.moreDownloadsText}</h2>

      <Tab.Group>
        <Tab.List className={cn(styles["tab-list"])}>
          {theme.allDownloads.map((download, index) => (
            <Tab key={download.downloadType + index}>
              {download.downloadType}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className={cn(styles["panel-list"])}>
          {downloads.map((downloadCategory, index) => (
            <Tab.Panel key={downloadCategory.downloadType + index}>
              <p>{downloadCategory.downloadTypeDescription}</p>
              <ul className={cn(styles["downloads-list"])}>
                {downloadCategory.downloads.map((downloadLink, index) => (
                  <li key={index}>
                    <Link
                      scroll={false}
                      href={downloadLink.download.url}
                      animateIcon
                    >
                      {downloadLink.download.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>

        <Tab.Arrows className={cn(styles["tab-arrows"])} />
      </Tab.Group>
    </div>
  );
}

export { DownloadTabs };
