import { createContext, useEffect, useMemo, useRef, useState } from "react";

const TabContext = createContext();

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function TabProvider({ children }) {
  const [tabAmount, setTabAmount] = useState(0);
  const [active, setActive] = useState(0);
  const previousActive = usePrevious(active);
  const [showArrows, setShowArrows] = useState(false);

  const value = useMemo(() => {
    return {
      tabAmount,
      setTabAmount,
      active,
      previousActive,
      setActive,
      showArrows,
      setShowArrows,
    };
  }, [tabAmount, active, previousActive, showArrows]);

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
}

export { TabContext, TabProvider };
