import styles from "./WYSIWYG.module.scss";
import { cn } from "@/lib/utils";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function WYSIWYG({ removeTags = false, className, children, ...props }) {
  const content = removeTags ? children.replace(/(<([^>]+)>)/gi, "") : children;

  return (
    <div
      className={cn("wysiwyg", styles["root"], { [className]: className })}
      {...props}
    >
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
    </div>
  );
}

export { WYSIWYG };
