import styles from "./Link.module.scss";
import * as heroIcons from "@heroicons/react/24/outline";
import { cn } from "@/lib/utils";
import * as NextLink from "next/link";

function Link({
  className,
  icon = "ArrowLongRightIcon",
  animateIcon = false,
  children,
  ...props
}) {
  const HeroIcon = heroIcons[icon];

  return (
    <NextLink
      className={cn(styles["link"], {
        [className]: className,
        [styles["link--animate-icon"]]: animateIcon,
      })}
      {...props}
    >
      <span>{children}</span>
      {HeroIcon && <HeroIcon />}
    </NextLink>
  );
}

export { Link };
